import React from "react"
import "@fontsource/open-sans" 

import Header from "../components/header.js"
import TabTitle from "../components/tabTitle.js"
import Heading from "../components/heading.js"
import Subtitle from "../components/subtitle.js"
import PageImage from "../components/pageImage.js"
import ByLine from "../components/byLine.js"
// import Button from "../components/button.js"
import Modal from "../components/modal"
import Footer from "../components/footer.js"

const mainStyles = {
  alignItems: "centre",
  textAlign: "center",
  margin: 0,
  padding: 0,
}
const innerStyles = {
  paddingTop: 20,
  paddingBottom: 20,
  marginLeft: '15%',
  marginRight: '15%',
  fontFamily: "Open Sans",
  textAlign: "center"
}

// data
const pagetext = {
  header: "Free Online Course for Intermediate and Elite Ultimate Players",
  tabTitle: "6 Things You Need",
  title: "6 Things You Need to Get a Starting Position in Your Dream Ultimate Team!",
  subtitle: "Over the last 10 years, I've helped over 10,000 Ultimate Frisbee players to earn more playing time & reach their full potential, quickly!",
  byline: "Presenter: Melissa Witmer - founder of the Ultimate Athlete Project",
  button: "Get Your Free Course NOW!"
}

// markup
const IndexPage = () => {

  return (
    <main style={mainStyles}>

      <TabTitle>{pagetext.tabTitle}</TabTitle>

      <Header>{pagetext.header}</Header>

      <div style={innerStyles}>

        <Heading>{pagetext.title}</Heading>

        <Subtitle>{pagetext.subtitle}</Subtitle>

        <PageImage />

        <ByLine>{pagetext.byLine}</ByLine>

        <Modal buttonText={pagetext.button} /> 

      </div>

      <Footer />

    </main>
  )
}

export default IndexPage
